import React from "react";
import { Layout } from "../layouts";

const NewsPage = (props) => (
  <Layout location={props.location}>
    <div className="content-wrap">
      <h1 className="content-h2">Aktuelles</h1>

      <p>
        Die Praxis ist vom 30.09. bis 04.10.24 geschlossen.
        <br />
        <br />
        Vertretung in dringenden Fällen:
        <br />
        Kai Brandau, Moltkestr.81, 76185 Karlsruhe, Tel. 0721/848946 (ab dem
        01.10.24)
        <br />
        <br />
      </p>
      <br />
    </div>
  </Layout>
);

export default NewsPage;
